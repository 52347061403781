import nogleLogo from '../../assets/img/noglelogowhite.svg';
import styles from './app.module.scss';

function App() {
  return (
    <div className={styles.app}>
      <div className={styles.mask} />
      <div className={styles.textContent}>
        <img width={114} height={52} alt="Nogle logo" src={nogleLogo} />
        
        <h1 className={styles.title}>Your Digital Future Built on Trust</h1>
        
        <p className={styles.subContent}>
          Nogle invests in technology to provide financial services
          built on governance and transparency.&nbsp;&nbsp;Website launching soon.&nbsp;&nbsp;Meanwhile,&nbsp;please visit <span className={styles.boldText}>&nbsp;Nogle Global&nbsp;</span> 
          for more information.
        </p>
        
        <a
          href="https://www.nogle.com/"
          target="_self"
          rel="noreferrer noopener"
          className={styles.link}
        >
          <div>  
            Nogle.com
            <span className={styles.arrow}>
              <span className={styles.shaft}></span>
            </span>
          </div>
        </a>
      </div>

    </div>
  );
}

export default App;
